import { deepCopy, toCamel, toUnderline } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from '@/services/api-helper'

// 取得待審核數量
export const GetAuditCountService = (): ApiService<Audit.AuditCount.Res> => {
  return request.get('/audit/count')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetAuditCountService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 入金申請列表
export const GetDepositListService = (query: Audit.DepositList.Req): ApiService<Audit.DepositList.Res> => {
  return request.get('/audit/deposit', { params: toUnderline(deepCopy(query)) })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetDepositListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 入金申請詳細訂單
export const GetDepositDetailService = (id: string): ApiService<Audit.DepositDetail.Res> => {
  return request.get(`/audit/deposit/${id}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetDepositDetailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 入金申請同意
export const ApproveDepositService = (id: string, query: { comment: string }): ApiService<boolean> => {
  return request.patch(`/audit/deposit/${id}/approve`, query)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('ApproveDepositService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })

}

// 入金申請駁回
export const RejectDepositService = (id: string, query: { comment: string }): ApiService<boolean> => {
  return request.patch(`/audit/deposit/${id}/reject`, query)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('RejectDepositService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}


// 出金申請列表
export const GetWithdrawListService = (query: Audit.WithdrawList.Req): ApiService<Audit.WithdrawList.Res> => {
  return request.get('/audit/withdraw', { params: toUnderline(deepCopy(query)) })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetWithdrawListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 出金申請詳細訂單
export const GetWithdrawDetailService = (id: string): ApiService<Audit.WithdrawDetail.Res> => {
  return request.get(`/audit/withdraw/${id}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetWithdrawDetailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 出金申請同意
export const ApproveWithdrawService = (id: string, query: { comment: string }): ApiService<boolean> => {
  return request.patch(`/audit/withdraw/${id}/approve`, query)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('ApproveWithdrawService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })

}

// 出金申請駁回
export const RejectWithdrawService = (id: string, query: { comment: string }): ApiService<boolean> => {
  return request.patch(`/audit/withdraw/${id}/reject`, query)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('RejectWithdrawService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 身份驗證申請列表
export const GetIdentityVerificationListService = (query: Audit.IdentityVerificationList.Req): ApiService<Audit.IdentityVerificationList.Res> => {
  return request.get('/subscriptions/identity_verification/investors', { params: toUnderline(deepCopy(query)) })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetIdentityVerificationListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 身份驗證申請列表詳細
export const GetIdentityVerificationDetailService = (id: string): ApiService<Audit.IdentityVerificationDetail.Res> => {
  return request.get(`/subscriptions/identity_verification/investors/${id}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetIdentityVerificationDetailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}


// 身份驗證申請同意
export const ApproveIdentityVerificationService = (id: string): ApiService<boolean>=> {
  return request.patch(`/subscriptions/identity_verification/investors/${id}/approve`)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('ApproveIdentityVerificationService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })

}

// 身份驗證申請駁回
export const RejectIdentityVerificationService = (id: string, query: { reason: string }): ApiService<boolean> => {
  const body = toUnderline(deepCopy(query))
  return request.patch(`/subscriptions/identity_verification/investors/${id}/reject`, body)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('RejectIdentityVerificationService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}



